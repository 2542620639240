import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { login, logout, updateUser } from "../redux/userSlice";
import { setUnAuthorized } from "../redux/globalSlice";
import ModalBox from "../common/modal";


export const globalData = createContext(null);

export const GlobalContext = (props) => {
  const unauthorized = useSelector((state) => state.global.unauthorized);

  const dispatch = useDispatch();
  const [allow, setAllow] = useState(false);

  const loginCheck = (usr2) => {
    if (usr2 && usr2.token) {
      // console.log("usss",usr2.token);
      
      setAllow(true);
      dispatch(updateUser(usr2));
    } else {
      setAllow(false);
      localStorage.removeItem("bsuser");
      dispatch(setUnAuthorized(true));
    }
  };


  useEffect(() => {
    const usr =localStorage.getItem("bsuser") 
    if (usr) {
      const usr2 = JSON.parse(usr);
      loginCheck(usr2);
    } else {
      setAllow(true);
    }
  }, []);

  const handleOk = async () => {
    // console.log("Logging out and resetting state...");
    dispatch(logout());
    dispatch(setUnAuthorized(false));
    setAllow(true);
    // console.log("State reset, user logged out");
  };

  return (
    <globalData.Provider value={null}>
      {allow && <>{props.children}</>}
      <ModalBox
        open={unauthorized}
        onClose={() => {}}
        header="Restricted !"
        message={"Login session expired. Please Login again !"}
        onOk={handleOk}
      />
    </globalData.Provider>
  );
};