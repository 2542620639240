import React, { useState } from "react";
import "./SignUp.css";
import LeftImg from "../../common/assest/leftimg.png";
import RightImg from "../../common/assest/rightimg.png";
import SnageLogo from "../../common/assest/BlackNameLogo.svg";
import EyeClose from "../../common/assest/ion_eye-off.svg";
import EyeOpen from "../../common/assest/opnEye.svg";
import {
  Box,
  Card,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CustomBtn from "../../common/custombtn/CustomBtn";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/userSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../common/loader/Loader";
import {loginApi} from '../../api'

const SignUp = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [user, setUser] = useState({ email: "admin@gmail.com", password: "password" });
  const [user, setUser] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [passToogle, setPassToogle] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  const handlePassowrdToggle = () => {
    setPassToogle(!passToogle);
  };

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const handleSubmit = async () => {
    if (!user.email || !user.password) {
      toast.warning("Email and Password required");
      return;
    }
    if (!validateEmail(user.email)) {
      toast.error("Please provide a valid email address.");
      return;
    }
    try {
      setLoading(true);
      const response = await loginApi(user);
      // console.log("af",response)
      dispatch(login({ ...response.userData, token: response.token }));
      navigate("/users");
      toast.success("Admin Logged in Successfully!")
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      const errorMessage = error?.data?.error;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };
  return (
  

        <div className="signup-container">

          {loading && <Loader/>}

          <div className="signup-grid">
            {/* Left Image */}
            <div className="left-image">
              <img src={LeftImg} alt="Left" />
            </div>

            {/* Right Image with Card */}
            <div className="right-image">
              <img src={RightImg} alt="Right" className="r1" />
            </div>
            <div className="card-container">
              <Card
                sx={{
                  width: { xs: "90%", sm: "420px" },
                  // margin: { xs: 'auto' },
                  // height: '525px',
                  height: "auto",
                  borderRadius: "31px",
                  backgroundColor: "#FCF9FF",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  position: "relative",
                  // margin: 'auto',
                  // top: '20%',
                }}
              >
                <div className="cheflogos">
                  <img
                    src={SnageLogo}
                    alt="logo"
                    style={{
                      height: "105px",
                      width: "105px",
                      marginTop: "15px",
                    }}
                  />
                  <div className="welcome-text">
                    <span style={{  background: "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)",
                          backgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          fontFamily:'Inter, sans-serif'
                          // display: "inline-block",
                        }}>
                          Welcome!</span> Let’s Get
                    Started!
                  </div>
                </div>

                <div className="email-pass" style={{ width: "90%" }}>
                  <Box sx={{ marginTop: "25px", width: "100%" }}>
                    <Typography
                      sx={{
                        marginBottom: "8px",
                        fontSize: "17px",
                        fontWeight: "600",
                        textIndent: "10px",
                        fontFamily:'Inter, sans-serif',
                      }}
                    >
                      Email
                    </Typography>
                    <TextField
                      type="email"
                      placeholder="Enter your Email"
                      name="email"
                      value={user.email}
                      onChange={handleInputChange}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "28px",
                          backgroundColor: "#F0F0F0",
                          fontSize: "14px",
                          fontFamily:'Inter, sans-serif',
                        },
                      }}
                    />
                  </Box>

                  <Box sx={{ marginTop: "10px", width: "100%" }}>
                    <Typography
                      sx={{
                        marginBottom: "8px",
                        fontSize: "17px",
                        fontWeight: "600",
                        textIndent: "10px",
                        fontFamily:'Inter, sans-serif',
                      }}
                    >
                      Password
                    </Typography>
                    <TextField
                      type={passToogle ? "text" : "password"}
                      placeholder="Enter Password"
                      name="password"
                      value={user.password}
                      onChange={handleInputChange}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              src={passToogle ? EyeOpen : EyeClose}
                              alt="toggle-password-visibility"
                              className="eyeicon"
                              onClick={handlePassowrdToggle}
                              style={{ width: "20px", cursor: "pointer" }}
                            />
                          </InputAdornment>
                        ),
                        style: {
                          borderRadius: "28px",
                          backgroundColor: "#F0F0F0",
                          fontSize: "14px",
                           fontFamily:'Inter, sans-serif',
                        },
                      }}
                    />
                  </Box>

                  <div
                    style={{
                      background: "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)",
                          backgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                      fontWeight: "500",
                      marginTop: "15px",
                      fontSize: "18px",
                      cursor: "pointer",
                       fontFamily:'Inter, sans-serif',
                    }}
                    onClick={() => navigate("/forgot")}
                  >
                    Forgot Password?
                  </div>

                  <div className="custom-btn-container">
                    <CustomBtn text="Sign In" onClick={handleSubmit} />
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>

  );
};

export default SignUp;
