import { createSlice, current } from '@reduxjs/toolkit';


const initialState = {
  user: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers:{
    login : (state, action)=>{
        state.user = action.payload; 
        localStorage.setItem("bsuser",JSON.stringify({...state.user}));
        
    },
    logout:(state)=>{
        state.user = null;
       
        localStorage.removeItem("bsuser");
    },
    updateUser : (state, action)=>{
      state.user= {...state.user, ...action.payload}
      if(localStorage.getItem("bsuser")){
        localStorage.setItem("bsuser",JSON.stringify(state.user));
      }
     
    }
  }
})

// Action creators are generated for each case reducer function
export const { 
    login,
    logout,
    updateUser
   } = userSlice.actions

export default userSlice.reducer