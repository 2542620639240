import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import SnagLogoWithWhiteName from "../assest/whiteNameLogo.svg";
import HomeLogo from "../assest/home.svg";
import UserShapeIcon from "../assest/combo_shape.svg";
import BookShapeIcon from "../assest/Combo_shape1.svg";
import UserShapeIconFilled from "../assest/Subtracts.svg";
// import LogoutIcon from ".
import "./Main.css";
import LogoutIcon from '@mui/icons-material/Logout';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import { logout } from "../../redux/userSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
const Sidebar = ({ isSidebarOpen, toggleSidebar, isMobileView }) => {
  const [activeTab, setActiveTab] = useState("users");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch=useDispatch();
  
  useEffect(() => {
    const currentPath = location.pathname;
    const matchingItem = menuItems.find(item => currentPath.includes(item.key));
    if (matchingItem) {
      setActiveTab(matchingItem.key);
    } else {
      setActiveTab("users");
    }
  }, [location]);
  

  const handleRoutes = (tab, path) => {
    setActiveTab(tab);
    navigate(path);
  };


  const handleLogout = () => {
    const isConfirmed = window.confirm("Are you sure you want to logout?");
    if (!isConfirmed) {
      return;
    } else {
      dispatch(logout());
      toast.success("Admin Logout");
    }
  };


  const menuItems = [
    { name: "Users", key: "users", icon: UserShapeIconFilled, route: "/users" },
    { name: "Vehicles", key: "vehicles", icon: UserShapeIconFilled, route: "/vehicles" },
    { name: "Looking for spots", key: "looking-for-spots", icon: UserShapeIcon, route: "/looking-for-spots" },
    { name: "Giving spots", key: "giving-spots", icon: BookShapeIcon, route: "/giving-spots" },
    { name: "Snags", key: "snags", icon: UserShapeIconFilled, route: "/snags" },
    { name: "Feedbacks", key: "feedbacks", icon: UserShapeIconFilled, route: "/feedbacks" },
    { name: "Support Tickets", key: "support-tickets", icon: UserShapeIconFilled, route: "/support-tickets" },
  ];
  

  return (
    <Drawer
      sx={{
        borderRadius: "0px 24px 24px 0px !important",
        "& .MuiPaper-root": {
          borderRadius: "0px 24px 24px 0px",
          border: "none",
          height: "100vh",
          backgroundColor: "none",
        },
      }}
      anchor="left"
      open={isSidebarOpen || !isMobileView}
      onClose={toggleSidebar}
      variant={isMobileView ? "temporary" : "persistent"}
    >
      <div
        style={{
          width: "244px",
          height: "100vh",
          borderRadius: "0px 24px 24px 0px",
          background: "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingLeft: "10px",
        }}
      >
        <div style={{ textAlign: "center", marginTop: "20px" ,marginLeft: "50px",marginRight:'70px'}}>
          <img src={SnagLogoWithWhiteName} alt="Chef Logo" style={{ width: "120px" }} />
        </div>

<List sx={{ marginTop: "20px" }}>
  {menuItems.map((item) => (
    <ListItem
      key={item.key}
      sx={{
        maxWidth: "230px",
        height: "60px",
        cursor: "pointer",
        borderRadius: "18px",
        fontFamily: "Inter, sans-serif",
        opacity: activeTab === item.key ? "1" : "0.6",
        color: activeTab === item.key ? "white" : "#B0B0B0",
        backgroundColor:
          activeTab === item.key
            ? "rgba(255, 255, 255, 0.3)"
            : "transparent",
      }}
      onClick={() => handleRoutes(item.key, item.route)}
    >
      <ListItemIcon sx={{ minWidth: "35px" }}>
        <img src={item.icon} alt={`${item.name} Icon`} />
      </ListItemIcon>
      <ListItemText
        primary={item.name}
        sx={{  fontFamily: "Inter, sans-serif", color: "inherit" }}
      />
    </ListItem>
  ))}
</List>


        <Divider sx={{ marginY: "5px", backgroundColor: "rgba(255, 255, 255, 0.5)" }} />

        <ListItem
          sx={{
            maxWidth: "230px",
            height: "60px",
            cursor: "pointer",
            borderRadius: "18px",
            color: "#B0B0B0",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
            },
          }}
          onClick={handleLogout}
        >
          <ListItemIcon sx={{ minWidth: "35px" }}>
            {/* <img sr c={LogoutIcon} alt="Logout Icon" /> */}
            <LogoutIcon  sx={{color:'white'}}/>
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            sx={{  fontFamily: "Inter, sans-serif", color: "inherit" }}
          />
        </ListItem>
      </div>
    </Drawer>
  );
};

export default Sidebar;
