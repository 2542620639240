import { createSlice, current } from '@reduxjs/toolkit'


const initialState = {
  unauthorized:false,
  alreadySignedIn : false,
  setNotification:false
}


export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers:{
    setUnAuthorized : (state, action)=>{
        state.unauthorized = action.payload
    },
    setAlreadySigned : (state, action)=>{
    
      state.alreadySignedIn = action.payload
  },
    setNotification:(state,action)=>{
     
      state.setNotification = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { 
    setUnAuthorized,
    setAlreadySigned,
    setNotification
   } = globalSlice.actions

export default globalSlice.reducer