import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import './Main.css';
import AuthenticatedLayout from '../layouts/AuthenticatedLayout'
const Layout = (Component) => {
  return function WithLayout() {
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [isMobileView, setMobileView] = useState(window.innerWidth <= 1024);
    

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 1024) {
          setMobileView(true);
          setSidebarOpen(false);
        } else {
          setMobileView(false);
          setSidebarOpen(true);
        }
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => {
      setSidebarOpen(!isSidebarOpen);
    };

    const handleTabClick = (tabName) => {
      // setActiveTab(tabName);
      if (isMobileView) {
        setSidebarOpen(false);
      }
    };

    return (
      <AuthenticatedLayout>
        <div>
          <Navbar toggleSidebar={toggleSidebar} />
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
            handleTabClick={handleTabClick}
            // activeTab={activeTab}
            isMobileView={isMobileView}
          />
          <div  className="whole-design-margin">
              <Component />
          </div>
        
        </div>
      </AuthenticatedLayout>
    );
  };
};

export default Layout;