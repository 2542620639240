import React, { useEffect, useState } from 'react'
import UsersRow from '../../common/tablerow/UsersRow'
import { useSelector } from 'react-redux';
import { getAllUserApi } from '../../api';
import { toast } from 'react-toastify';
import { TableCell, TableRow } from '@mui/material';
import Loader from "../../common/loader/Loader";
import TableComp from "../../common/tablecomp/TableComp";
import TableCard from "../../common/tableCard/TableCard";
import Paginations from '../../common/pagintaion/Pagination';


const User = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const [loading,setLoading]=useState(false);
  const [currentPage,setCurrentPage]=useState(1)
  const [totalPage,setTotalPage]=useState()
  const [allUsersData,setAllUsersData]=useState([])

 



  const handleUsersDetails=async()=>{
    try {
      setLoading(true)
      const response=await getAllUserApi({token,currentPage})
      console.log("op",response);
      setAllUsersData(response?.data)
      setCurrentPage(parseInt(response?.page))
      setTotalPage(response?.pages)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }
  
useEffect(()=>{
  handleUsersDetails()
},[currentPage])


const titles = [
  { title: "User Name" },
  { title: "Email" },
  { title: "Phone Number" },
  { title: "Ratings" },
  { title: "Joined Date" },
];

const handlePageChange = (page) => {
  setLoading(true);
  setCurrentPage(page);
};

  return (
    <>
    
    
    {loading ?(
      
      <Loader/>
    ):(
        
        <TableCard>
          <TableComp title={titles}>
          {allUsersData?.length >0 ? (
                allUsersData?.map((item) => (
                  <UsersRow 
                  row={item} 
                  key={item?._id}
                  // handleViewMore={() => openModal(item)} 
                  // deleteUser={()=>handleDeleteUser(item?._id)} 
                  // suspendUser={()=>handleSuspendUser(item?._id,item)}
                  // key={item?._id}
                  />
                ))
          ):(
                <TableRow>
                  <TableCell colSpan={titles.length} align="center" sx={{fontFamily:'Rosario',fontWeight:"600"}}>
                    No Data Found
                  </TableCell>
                </TableRow>
          )}
         
           <Paginations
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={totalPage}
              disabled={loading}
            />
        </TableComp>
        </TableCard>

           )}
           
    </>
  )
}

export default User