import React, { useCallback } from 'react';
import Pagination from '@mui/material/Pagination';
import { Box } from '@mui/system';
import { PaginationItem, Stack } from '@mui/material';

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const Paginations = ({ totalPages = 0, currentPage =0, onPageChange, disabled }) => {
  const debouncedOnPageChange = useCallback(
    debounce((event, value) => {
      // Only call onPageChange if the new page is different
      if (value !== currentPage) {
        onPageChange(value); 
      }
    }, 300),
    [onPageChange] 
  );

  return (
    <Box sx={{ display: 'flex', justifyContent: 'start', marginTop: 4 }}>
      <Stack spacing={2}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={debouncedOnPageChange}
          disabled={disabled}
          shape="rounded"
          renderItem={(item) => (
            <PaginationItem
              {...item}
              sx={{
                '&.Mui-selected': {
                  background: "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)",
                  color: '#FFF', 
                  borderRadius: '11px'
                },
              }}
              components={{
                previous: () => <span style={{ 
                  background: "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  
                  fontWeight: '600' 
                }}>
                  Previous</span>,
                next: () => <span style={{   background: "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                   fontWeight: '600' }}>Next</span>,
              }}
            />
          )}
        />
      </Stack>
    </Box>
  );
};

export default Paginations;
