import React from 'react';
import { Button } from '@mui/material';
import './CustomBtn.css'
const CustomBtn = ({ onClick, text,disable }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disable}
      sx={{
        
        width: "100%",
        maxWidth: "380px",
        height: "55px",
        flexShrink: 0,
        borderRadius: "100px",
        background: "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)",
        boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
        color: "#FFF !important",
        textAlign: "center",
        fontFamily: "Inter, sans-serif",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "13px",
      '@media (max-width: 480px)': {
          height: '45px', 
          fontSize: '14px', 
          borderRadius: '50px', 
        },
      }}
      
    >
      {text}
    </Button>
  );
};

export default CustomBtn;
