import { Axios } from "./axios.interceptor";

// ************************************************ login Api*****************************************************
export const loginApi = (user) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.post(
                "/login/admin",
                {
                    email: user.email,
                    password: user.password,

                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};


export const getAllUserApi=({token,currentPage})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/all-users?page=${currentPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


export const getAllVehicalApi=({token,currentPage})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/all-vehicles?page=${currentPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}
