import React from 'react'
import { Card } from '@mui/material'
import './CustomCard.css'

const CustomCard = ({children}) => {
  return (
    <div className='custom-card-container'>
    <Card
    sx={{
        width: { xs: '90%', sm: '420px' },
        margin: { xs: 'auto' },
        // height: '525px',
        height:'auto',
        borderRadius: '31px',
        backgroundColor: '#FCF9FF',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        position: 'relative'
        // margin: 'auto', 
        // top: '20%',
    }}
>
     {children}  
    </Card>
    </div>
  )
}

export default CustomCard