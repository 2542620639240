import { configureStore } from '@reduxjs/toolkit';
import globalSlice from './globalSlice';
import userSlice from './userSlice';



export const store = configureStore({
  reducer: { 
    global : globalSlice,
    user : userSlice
  },
})
