
import { Card, Divider, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
// import Paginations from "../pagintaion/Pagination";

 
const TableComp = ({
  title,
  children,
  emptySColumnCount = 0,
  emptyEColumnCount = 0,
}) => {



  const renderEmptyColumns = (count) => {
    const columns = [];
    for (let i = 0; i < count; i++) {
      columns.push(<TableCell key={`empty-${i}`} w={"200px"}></TableCell>);
    }
    return columns;
  };
 
  return (
    <Card
      backgroundColor={"#fff"}
      borderRadius={"35px"}
    //   style={{
    //     boxShadow: "0px 10px 99px #00000029",
    //   }}
    //   textAlign={"center"}
    //   align={"center"}
      w={"100%"}
      h={"auto"}
     sx={{padding:'20px',boxShadow: "0px 10px 99px #00000029"}}
    >
      <TableContainer
        w={"full"}
        maxH={"80vh"}
        overflowY={"auto"}
        overflowX={"auto"}
        size={"medium"}
      >
        <Table  size={"medium"}>
          <TableHead>
            <TableRow >
              {renderEmptyColumns(emptySColumnCount)}
              {title &&
                title.map((item, index) => (
                  <TableCell
                    key={index}
                    color={"#000"}
                    // fontSize={fontSize}
                    whiteSpace="pre-wrap"
                    wordWrap={true}
                    textOverflow="ellipsis"
                    sx={{ fontFamily: "Inter, sans-serif",fontWeight:'600',fontSize:'18px'}}
                  >
                    {item.title}
                  </TableCell>
                ))}
              {renderEmptyColumns(emptyEColumnCount)}
            </TableRow>
          </TableHead>
          {/* <Divider /> */}
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
 
    </Card>
  );
};
 
export default TableComp;