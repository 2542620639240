import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomBtn from "../../common/custombtn/CustomBtn";
import OTPInput from "react-otp-input";
import "./forgot.css";
// import { verifyEmailApi, verifyOTPApi } from '../../api';
import { toast } from "react-toastify";
import Loader from "../../common/loader/Loader";

const OTPVerify = ({ otpData, onNext }) => {
  const [otp, setOtp] = useState(NaN);
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleResendOtp = async () => {
    try {
      // const response = await verifyEmailApi({email:otpData.reference});
      // toast.success("OTP Resend Sucessfully!")
      //   const successMsg = response?.message;
      //     if (Array.isArray(successMsg)) {
      //         successMsg.forEach((msg) => toast.success(msg));
      //     } else if (successMsg) {
      //         toast.success(successMsg);
      //     }e
    } catch (error) {
      console.log("error", error);
      const errorMessage = error?.data?.error;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const handleOtpSubmit = async () => {
    // if (otp.length !== 4) {
    //     toast.warning("Please enter a 4-digit OTP.");
    //     return;
    // }
    try {
      // const response = await verifyOTPApi({email:otpData.reference,otp})
      // console.log("verifying otp",response);
      // const successMsg = response?.message;
      // if (Array.isArray(successMsg)) {
      //     successMsg.forEach((msg) => toast.success(msg));
      // } else if (successMsg) {
      //     toast.success(successMsg);
      // }

      onNext();
    } catch (error) {
      //  setOtp("")
      console.log("error", error);
      const errorMessage = error?.data?.error;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const otpBgHandle = (props) => {
    const { value } = props;
    return (
      <input
        {...props}
        className="responsive-input"
        style={{
          width: "60px",
          height: "60px",
          borderRadius: "10px",
          border: isFocused || value ? "1px solid #4449FE" : "none",
          fontSize: "1.8rem",
          textAlign: "center",
          margin: "0 8px",
          outline: "none",
          backgroundColor: "#FFF",
          color: "#000",
          boxShadow: "0 0 45px rgba(0, 0, 0, 0.06);",
          fontFamily: "Inter, sans-serif",
        }}
      />
    );
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="fogot-container">
          <div className="fogot-pass-txt">
            <span
              style={{
                fontFamily: "Inter, sans-serif",
                background:
                  "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Code
            </span>{" "}
            Verification
          </div>
          <div
            style={{
              fontFamily: "Inter, sans-serif",
              color: "#A8A8A8FC",
              marginTop: "10px",
            }}
          >
            Code is sent to{" "}
            <span style={{ fontFamily: "Inter, sans-serif", color: "#000" }}>
              {otpData?.reference || "Email not avilable"}
            </span>
          </div>

          <Box sx={{ margin: "15px auto", width: "100%", padding: "20px" }}>
            <OTPInput
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
              renderInput={otpBgHandle}
            />
          </Box>

          <div
            style={{
              fontFamily: "Inter, sans-serif",
              color: "#000",
              marginBottom: "25px",
            }}
          >
            Didn’t receive any code?{" "}
            <span
              style={{
                fontFamily: "Inter, sans-serif",
                background:
                  "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
                cursor: "pointer",
              }}
              onClick={handleResendOtp}
            >
              {" "}
              Request again
            </span>
          </div>

          <CustomBtn text="VERIFY" onClick={handleOtpSubmit} />
        </div>
      )}
    </div>
  );
};

export default OTPVerify;
