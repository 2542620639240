import React, { useEffect, useState } from 'react'
import UsersRow from '../../common/tablerow/UsersRow'
import { useSelector } from 'react-redux';
import { getAllUserApi, getAllVehicalApi } from '../../api';
import { toast } from 'react-toastify';
import { TableCell, TableRow } from '@mui/material';
import Loader from "../../common/loader/Loader";
import TableComp from "../../common/tablecomp/TableComp";
import TableCard from "../../common/tableCard/TableCard";
import VehiclesRow from '../../common/tablerow/VehiclesRow';
import Paginations from '../../common/pagintaion/Pagination';


const Vehicles = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const [loading,setLoading]=useState(false);
  const [currentPage,setCurrentPage]=useState(1)
  const [totalPage,setTotalPage]=useState()
  const [allVehicalData,setAllVehicalData]=useState([])
  
 



  const handleVehiclesDetails=async()=>{
    try {
      setLoading(true)
      const response=await getAllVehicalApi({token,currentPage})
      // console.log("v",response);

      setAllVehicalData(response?.data)
      setCurrentPage(parseInt(response?.page))
      setTotalPage(response?.pages)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = error?.data?.error;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }
  
useEffect(()=>{
  handleVehiclesDetails()
},[currentPage])


const titles = [
  { title: "User Name" },
  { title: "Email" },
  { title: "Phone Number" },
  // { title: "Picture" },
  { title: "Model" },
  { title: "Color" },
  { title: "license Plate Number" },
  { title: "Make" },
];

const handlePageChange = (page) => {
  setLoading(true);
  setCurrentPage(page);
};


  return (
    <>
    
    
    {loading ?(
      
      <Loader/>
    ):(
        
        <TableCard>
          <TableComp title={titles}>
          {allVehicalData?.length >0 ? (
                allVehicalData?.map((item) => (
                  <VehiclesRow 
                  row={item} 
                 key={item?._id}
                  // handleViewMore={() => openModal(item)} 
                  // deleteUser={()=>handleDeleteUser(item?._id)} 
                  // suspendUser={()=>handleSuspendUser(item?._id,item)}
               
                  />
                ))
          ):(
                <TableRow>
                  <TableCell colSpan={titles.length} align="center" sx={{fontFamily:'Rosario',fontWeight:"600"}}>
                    No Data Found
                  </TableCell>
                </TableRow>
          )}
         
           <Paginations
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={totalPage}
              disabled={loading}
            />
        </TableComp>
        </TableCard>

           )}
           
    </>
  )
}

export default Vehicles