import React from 'react'
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './common/main/Layout';
import SignUp from './Pages/signup/SignUp';
import User from './Pages/user/User';
import Vehicles from './Pages/vehicles/Vehicles';
import Spots from './Pages/lookingspots/Spots';
import GivingSpots from './Pages/givingspots/GivingSpots';
import Snags from './Pages/snags/Snags';
import FeedBack from './Pages/feedback/FeedBack';
import SupportTicket from './Pages/supportTicket/SupportTicket';
import EmailVerify from './Pages/forgot/EmailVerify';
function App() {
  return (
    <div className="App">
    <ToastContainer />
     <Router>
        <Routes>
          <Route path="/" element={<SignUp/>} />
          <Route path="/forgot" element={<EmailVerify/>} />
          {/* <Route path="/users" element={Layout(Dashboard)()}/> */}
           <Route path="/users" element={Layout(User)()}/>

            <Route path="/vehicles" element={Layout(Vehicles)()} />
            <Route path="/looking-for-spots" element={Layout(Spots)()} />
            <Route path="/giving-spots" element={Layout(GivingSpots)()} />
            <Route path="/snags" element={Layout(Snags)()} />
            <Route path="/feedbacks" element={Layout(FeedBack)()} />
            <Route path="/support-tickets" element={Layout(SupportTicket)()} />


        </Routes>
        </Router>

</div>
  );
}

export default App;
